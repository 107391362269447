import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class Packers extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('common.mainMenu.reportPackers');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/bag-stickers"
        pdfUrl="reports/packers-v2"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_PACKERS}
        pdfAvaliable={true}
        excelAvaliable={false}
        archiveReportNames={['PACKERS']}
        dateType="single"
        isSalesMarketLangViewEnabled={true}
      />
    );
  }
}

export default withTranslation()(Packers);
