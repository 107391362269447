import PropTypes from 'prop-types';
import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import { get } from 'helpers/apiHelpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';

//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import SelectAll from 'components/SelectAll';
import ReportConfigurator from 'components/Report/ReportConfigurator';
import MultiplierCalculator from 'components/MultiplierCalculator/MultiplierCalculator';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from '../../components/Security/AccessDenied';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import moment from 'moment';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import Archive from '../../components/Archive/Archive';
import { fetchZoneCategories } from '../../actions/ZoneCategories';
import roles, { ROLE_SHOW_ZONE_CATEGORY } from '../../helpers/roles';
import Cookies from 'cookies-js';
import CardWrapper from 'components/Card/CardWrapper';
import DownloadReportButtons from './DownloadReportButtons/DownloadReportButtons';
import MIME_TYPES from './constants/mimeTypes';
import MIME_TYPES_DATA from './constants/mimeTypesData';

const DEFAULT_STATE = () => ({
  mealCategoriesOptions: [],
  selectedBrands: [],
  selectedMealCategories: [],
  selectedZoneCategories: [],
  date: '',
  dateFrom: '',
  dateTo: '',
  multiplier: 1,
  isGenerating: false,
  multiplierCalculatorOpen: false,
});

class BaseBrandReport extends React.Component {
  state = DEFAULT_STATE();

  componentDidMount = async () => {
    if (
      this.props.useZoneCategories &&
      isGranted(roles.ROLE_SHOW_ZONE_CATEGORY)
    ) {
      await this.props.fetchZoneCategories();
    }
    if (this.props.useMealCategories) {
      const mealCategoriesResponse = await get('/meal-categories');
      await this.setState(prevState => ({
        ...prevState,
        mealCategoriesOptions: mealCategoriesResponse['hydra:member'],
      }));
    }
    if (Cookies.get('base_brand_report')) {
      const tmpObject = {
        ...DEFAULT_STATE(),
        ...JSON.parse(Cookies.get('base_brand_report')),
      };
      if (tmpObject.hasOwnProperty('mealCategoriesOptions')) {
        delete tmpObject.mealCategoriesOptions;
      }
      if (!this.props.useMealCategories) {
        tmpObject.selectedMealCategories = [];
      }
      if (!this.props.useZoneCategories) {
        tmpObject.selectedZoneCategories = [];
      }
      this.setState(prevState => ({
        ...prevState,
        ...tmpObject,
      }));
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    switch (this.props.dateType) {
      case 'range':
        return !!(this.state.dateFrom && this.state.dateTo);
      case 'single':
        return !!this.state.date;
      default:
        return true;
    }
  };

  validateBrands = () => {
    return this.state.selectedBrands.length !== 0;
  };

  handleGenerate = (mimeType, lang) => {
    const { t } = this.props;
    this.setState({ isGenerating: true });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t(
            'errors.raportRequiresDate',
            'Wybierz datę, dla której chcesz wygenerować raport'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (!this.validateBrands()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t('errors.raportRequireBrand', 'Wybierz przynajmniej jedną markę'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    Cookies.set('base_brand_report', JSON.stringify(this.state));

    const params = {
      ...(this.props.dateType === 'range' && {
        dateFrom: this.state.dateFrom,
        dateTo: this.state.dateTo,
        multiplier: this.state.multiplier,
      }),
      ...(this.props.dateType === 'single' && {
        date: this.state.date,
        multiplier: this.state.multiplier,
      }),
      ...(this.props.additionalParams || {}),
      brands: this.state.selectedBrands.map(brand => brand.value),
      mealCategories: this.state.selectedMealCategories.map(
        mealCategory => mealCategory.value
      ),
      zoneCategories: this.state.selectedZoneCategories.map(
        zoneCategory => zoneCategory.value
      ),
    };

    const config = {
      [MIME_TYPES.csv]: {
        url: this.props.csvUrl || this.props.excelUrl,
        extension: MIME_TYPES_DATA[MIME_TYPES.csv].extension,
      },
      [MIME_TYPES.pdf]: {
        url: this.props.pdfUrl,
        extension: MIME_TYPES_DATA[MIME_TYPES.pdf].extension,
      },
    }[mimeType] || {
      url: this.props.excelUrl,
      extension: MIME_TYPES_DATA[MIME_TYPES.xlsx].extension,
    };

    const getFileNameDates = () => {
      switch (this.props.dateType) {
        case 'range':
          return {
            date: '',
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
          };
        case 'single':
          return { date: this.state.date, dateFrom: '', dateTo: '' };
        default:
          return {};
      }
    };

    const fileName =
      this.props.fileName(getFileNameDates()) + '.' + config.extension;

    axios
      .get(config.url, {
        responseType: 'blob',
        params: params,
        headers: { accept: mimeType },
        forcedLanguage: lang,
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(
              t(
                'reports.noAccess',
                'Nie posiadasz uprawnień do wygenerowania tego raportu. Skontaktuj się z administratorem.'
              )
            );
          }
        },
        error => {
          this.props.openToast({
            messages: [
              t('reports.cannotGenerate', 'Nie udało się wygenerować raportu'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });

        if (this.props.onAfterGenerate) {
          this.props.onAfterGenerate();
        }
      });
  };

  render() {
    const {
      classes,
      title,
      pdfAvaliable,
      csvAvailable,
      excelAvaliable,
      company,
      reportConfiguration,
    } = this.props;
    const companyId = parseInt(company.split('/').pop());
    const reportKey = reportConfiguration?.report || false;
    const fields = reportConfiguration?.fields;

    return isGranted(this.props.role) ? (
      <>
        <CardWrapper
          title={
            <>
              {title}{' '}
              {reportKey && (
                <ReportConfigurator
                  companyId={companyId}
                  report={reportKey}
                  fields={fields}
                />
              )}
            </>
          }
          style={{ marginTop: '20px' }}
        >
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>
                  {this.props.t('reports.generate', 'Trwa generowanie raportu')}
                </h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>
          <CardBody>
            <GridContainer justify="flex-start">
              <GridItem
                sm={
                  this.props.showMultiplier && this.props.useZoneCategories
                    ? 2
                    : this.props.showMultiplier || this.props.useZoneCategories
                    ? 4
                    : this.props.dateType
                    ? 6
                    : 12
                }
              >
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: '2px',
                    textAlign: 'start',
                    width: '100%',
                  }}
                >
                  {this.props.t('reports.selectBrands')}
                </FormLabel>
                <SelectAll
                  className="input-select--brand"
                  options={this.props.brands}
                  trackBy={['@id']}
                  mapBy={'name'}
                  optionSelected={this.state.selectedBrands}
                  handleChange={selected => {
                    this.setState({
                      selectedBrands: selected,
                    });
                  }}
                />
              </GridItem>
              {isGranted(ROLE_SHOW_ZONE_CATEGORY) &&
                this.props.useZoneCategories && (
                  <GridItem sm={2}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '2px' }}
                    >
                      {this.props.t('reports.selectZoneCategories')}
                    </FormLabel>
                    <SelectAll
                      className="input-select--zone-category"
                      options={this.props.zoneCategories}
                      trackBy={'@id'}
                      mapBy={'name'}
                      optionSelected={this.state.selectedZoneCategories}
                      handleChange={selected => {
                        this.setState({
                          selectedZoneCategories: selected,
                        });
                      }}
                    />
                  </GridItem>
                )}
              {this.props.showMultiplier === true && (
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '5px' }}
                  >
                    {this.props.t('common.multiplier')}
                  </FormLabel>
                  <FormControl fullWidth>
                    <FormTextInput
                      className="input-text--multiplier"
                      classes={classes}
                      name="multiplier"
                      value={this.state.multiplier}
                      type={'number'}
                      handleChange={this.handleChange}
                      inputSize={12}
                      onFocus={() =>
                        this.setState({ multiplierCalculatorOpen: true })
                      }
                      inputRef={node => {
                        this.anchorElement = node;
                      }}
                    />
                    <MultiplierCalculator
                      classes={classes}
                      url={'/statistics/ecommerce/bags/day/'}
                      open={this.state.multiplierCalculatorOpen}
                      anchorElement={this.anchorElement}
                      brandIds={this.state.selectedBrands}
                      handleChange={(name, value) =>
                        this.handleChange({
                          target: {
                            name: name,
                            value: value,
                          },
                        })
                      }
                    />
                  </FormControl>
                </GridItem>
              )}
              {this.props.dateType === 'range' && (
                <>
                  <GridItem sm={3}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('reports.dateFrom')}
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        className="input-datetime--date-from"
                        isValidDate={isDatepickerValidDay}
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.dateFrom)}
                        onChange={ev =>
                          this.setState({ dateFrom: ev.format('YYYY-MM-DD') })
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem sm={3}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('reports.dateTo')}
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        className="input-datetime--date-to"
                        isValidDate={isDatepickerValidDay}
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.dateTo)}
                        onChange={ev =>
                          this.setState({ dateTo: ev.format('YYYY-MM-DD') })
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </>
              )}
              {this.props.dateType === 'single' && (
                <>
                  <GridItem sm={6}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('reports.selectDate')}
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        className="input-datetime--date"
                        isValidDate={isDatepickerValidDay}
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.date)}
                        onChange={ev =>
                          this.setState({ date: ev.format('YYYY-MM-DD') })
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </>
              )}
              {this.props.useMealCategories && (
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '2px' }}
                  >
                    {this.props.t(
                      'common.mainMenu.mealCategory',
                      'Kategoria posiłków'
                    )}
                  </FormLabel>
                  <SelectAll
                    className="input-select--meal-categories"
                    options={this.state.mealCategoriesOptions}
                    trackBy={'@id'}
                    mapBy={'value'}
                    optionSelected={this.state.selectedMealCategories}
                    handleChange={selected => {
                      this.setState({
                        selectedMealCategories: selected,
                      });
                    }}
                  />
                </GridItem>
              )}
              {this.props.children}
            </GridContainer>
            {(pdfAvaliable || csvAvailable || excelAvaliable) && (
              <GridContainer justify="flex-end">
                <GridItem>
                  <DownloadReportButtons
                    isSalesMarketLangViewEnabled={
                      this.props.isSalesMarketLangViewEnabled
                    }
                    mimeTypes={[
                      pdfAvaliable && MIME_TYPES.pdf,
                      csvAvailable && MIME_TYPES.csv,
                      excelAvaliable && MIME_TYPES.xlsx,
                    ].filter(Boolean)}
                    handleGenerate={this.handleGenerate}
                    areButtonsDisabled={this.state.isGenerating}
                  />
                </GridItem>
              </GridContainer>
            )}
          </CardBody>
        </CardWrapper>
        <Card>
          <CardBody>
            <Archive
              type={['REPORT']}
              reportName={this.props.archiveReportNames}
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapDispatchToProps = dispatch => ({
  fetchZoneCategories: () => dispatch(fetchZoneCategories()),
});

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
  zoneCategories: state.ZoneCategories.zoneCategories,
  company: state.Auth.user.company,
});

BaseBrandReport.defaultProps = {
  isSalesMarketLangViewEnabled: false,
};

BaseBrandReport.propTypes = {
  brands: PropTypes.array,
  zoneCategories: PropTypes.array,
  classes: PropTypes.any,
  archiveReportNames: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.func.isRequired,
  showMultiplier: PropTypes.bool,
  mimeType: PropTypes.string.isRequired,
  openToast: PropTypes.any,
  title: PropTypes.string,
  dateType: PropTypes.string,
  useZoneCategories: PropTypes.bool,
  useMealCategories: PropTypes.bool,
  url: PropTypes.string.isRequired,
  onAfterGenerate: PropTypes.func,
  isSalesMarketLangViewEnabled: PropTypes.bool,
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(BaseBrandReport);
