import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';

import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

import MIME_TYPES from './constants/mimeTypes';

class ActiveDiets extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('reports.reportActiveDiets');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="/reports/active-diets"
        pdfUrl="/reports/active-diets"
        mimeType={MIME_TYPES.xlsx}
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_ACTIVE_DIET}
        pdfAvaliable={true}
        excelAvaliable={true}
        dateType="range"
        archiveReportNames={['ACTIVE_DIETS']}
        useZoneCategories={true}
        isSalesMarketLangViewEnabled={true}
      />
    );
  }
}

export default withTranslation()(ActiveDiets);
