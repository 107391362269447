// material-ui components
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { goldColor } from 'assets/jss/material-dashboard-pro-react';

const RegularCheckbox = ({ classes, onClick = () => {}, checked }) => {
  return (
    <Checkbox
      tabIndex={-1}
      name="active"
      onClick={onClick}
      checked={checked}
      checkedIcon={
        <Check className={classes.checkedIcon} style={{ color: goldColor }} />
      }
      icon={
        <Check style={{ color: goldColor }} className={classes.uncheckedIcon} />
      }
      classes={{
        checked: classes.checked,
        root: classes.checkRoot,
      }}
      style={{ color: `${goldColor} !important` }}
    />
  );
};

export default withStyles(extendedFormsStyle)(RegularCheckbox);
