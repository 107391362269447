import React from 'react';
import MIME_TYPES from './constants/mimeTypes';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class PersonalPickups extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('common.mainMenu.reportPersonalPickups');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="/reports/personal-pickups"
        mimeType={MIME_TYPES.xlsx}
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_PERSONAL_PICKUPS}
        pdfAvaliable={false}
        excelAvaliable={true}
        dateType="single"
        archiveReportNames={['PERSONAL_PICKUPS']}
        isSalesMarketLangViewEnabled={true}
      />
    );
  }
}

export default withTranslation()(PersonalPickups);
