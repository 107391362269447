import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Info } from '@material-ui/icons';
import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';

import { combineStyles } from 'helpers/helpers';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

const InfoBannerCard = ({
  showBannerTitle,
  classes,
  getImage,
  bannerText,
  removeImage,
  bannerHeader,
  handleInputChange,
  infoBannerTooltip,
  infoBannerCheckbox,
  infoBannerMobileUrl,
  handleCheckboxChange,
  infoBannerDesktopUrl,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <GridItem lg={6}>
        <FormControlLabel
          control={
            <Checkbox
              onClick={handleCheckboxChange}
              checked={infoBannerCheckbox}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={
            <div style={{ display: 'flex' }}>
              {showBannerTitle ?? t('cms.showInfoBanner')}{' '}
              {infoBannerTooltip && (
                <div style={{ marginLeft: '10px' }}>
                  <Tooltip
                    title={
                      <div>
                        <h4>{infoBannerTooltip}</h4>
                      </div>
                    }
                    placement="right"
                  >
                    <Info fontSize={'small'} />
                  </Tooltip>
                </div>
              )}
            </div>
          }
        />

        <FormTextInput
          multiline
          rows={3}
          rowsMax={10}
          label={
            <div style={{ display: 'flex' }}>
              <span>{t('brandCfg.captionInBanner')}</span>
              <div
                style={{
                  marginLeft: '10px',
                  display: 'inline-block',
                }}
              >
                <Tooltip
                  title={
                    <div>
                      <h4>{t('brandCfg.captionInBannerTooltip')}</h4>
                      <h4>
                        {t('brandCfg.etc')}{' '}
                        {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt nagłówka</div>`}
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </div>
            </div>
          }
          classes={classes}
          name="bannerHeader"
          value={bannerHeader}
          handleChange={handleInputChange}
          inputSize={12}
          maxLength={160}
        />

        <FormTextInput
          label={
            <div style={{ display: 'flex' }}>
              <span>{t('brandCfg.textUnderCaption')}</span>
              <div
                style={{
                  marginLeft: '10px',
                  display: 'inline-block',
                }}
              >
                <Tooltip
                  title={
                    <div>
                      <h4>{t('brandCfg.textUnderCaptionTooltip')}</h4>
                      <h4>
                        {t('brandCfg.etc')}{' '}
                        {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt pod nagłówkiem</div>`}
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </div>
            </div>
          }
          classes={classes}
          name="bannerText"
          value={bannerText}
          handleChange={handleInputChange}
          inputSize={12}
          maxLength={1000}
          rows={3}
          rowsMax={20}
          multiline={true}
        />
      </GridItem>
      <GridItem lg={6}>
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              textAlign: 'center',
            }}
          >
            <div style={{ marginRight: '36px' }}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginTop: '15px', marginBottom: '24px' }}
              >
                {t('brandCfg.bannerPanorama')}
              </FormLabel>
              <FormImageUpload
                classes={classes}
                stateName="infoBannerDesktop"
                buttonText={t('common.shared.select', 'Wybierz')}
                imgId="desktop-banner"
                tooltipImageSize={t('brandCfg.widthMinPanorama')}
                getImage={getImage}
                removeImage={removeImage}
                previewUrl={infoBannerDesktopUrl ?? null}
              />
            </div>

            <div>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginTop: '15px', marginBottom: '24px' }}
              >
                {t('brandCfg.bannerPortrait')}
              </FormLabel>
              <FormImageUpload
                classes={classes}
                stateName="infoBannerMobile"
                buttonText={t('common.shared.select', 'Wybierz')}
                imgId="mobile-banner"
                tooltipImageSize={t('brandCfg.widthPortrait')}
                getImage={getImage}
                removeImage={removeImage}
                previewUrl={infoBannerMobileUrl}
              />
            </div>
          </div>
        </>
      </GridItem>
    </Fragment>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(InfoBannerCard);
