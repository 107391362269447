import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import getReportFileName from 'helpers/getReportFileName';

class AlteredDietsChecklist extends React.Component {
  state = {
    includeSubscriptions: false,
    includeShopElements: false,
  };

  render() {
    const { classes, t } = this.props;
    const title = t('reports.reportAlteredDietsChecklist');
    return (
      <BaseBrandReport
        title={title}
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_CHECKLIST_ALTERED_DIETS}
        pdfAvaliable={true}
        pdfUrl="reports/checklist-altered-diets"
        additionalParams={this.state}
        dateType="single"
        showMultiplier={true}
        archiveReportNames={['CHECK_LIST_ALTERED_DIETS']}
        useZoneCategories={true}
        isSalesMarketLangViewEnabled={true}
      >
        <GridItem sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                className="input-checkbox-include-subscriptions"
                checked={this.state.includeSubscriptions}
                onChange={e => {
                  this.setState({
                    includeSubscriptions: !this.state.includeSubscriptions,
                  });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t('reports.includeSubscriptions')}
          />
        </GridItem>
        <GridItem sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                className="input-checkbox-include-shop-elements"
                checked={this.state.includeShopElements}
                onChange={e => {
                  this.setState({
                    includeShopElements: !this.state.includeShopElements,
                  });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t('reports.includeShopElements')}
          />
        </GridItem>
      </BaseBrandReport>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(AlteredDietsChecklist);
