import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';

import DetailsDialog from './DetailsDialog';
import CardWrapper from 'components/Card/CardWrapper';
import { goldColor } from 'assets/jss/material-dashboard-pro-react';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  description: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box !important',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    minHeight: '54px',
  },
  button: { backgroundColor: goldColor },
});

const AdditionalOfferTile = ({ title, description, ...restProps }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <>
      {/* POPUP WITH DETAILS */}
      <DetailsDialog
        title={title}
        description={description}
        isDialogOpened={isDialogOpened}
        setIsDialogOpened={setIsDialogOpened}
        {...restProps}
      />

      {/* CARD WITH DATA */}
      <CardWrapper title={title}>
        <CardBody>
          <p className={classes.description}>{description}</p>
        </CardBody>
        <CardFooter>
          <GridContainer justify="flex-end">
            <Button
              className={classes.button}
              onClick={() => setIsDialogOpened(true)}
            >
              {t(
                '$*additionalOffer.detailsDialog.readMore',
                'Czytaj więcej ...'
              )}
            </Button>
          </GridContainer>
        </CardFooter>
      </CardWrapper>
    </>
  );
};

export default AdditionalOfferTile;
