import React from 'react';
import { NavLink } from 'react-router-dom';
import { getNumberFromString } from '../../helpers/helpers';
import Danger from '../../components/Typography/Danger';
import YesNoFilter from 'components/Grid/Filter/YesNo';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';

const columnConfig = t => {
  return [
    {
      title: 'ID',
      accessor: 'id',
      name: 'id',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.active',
      accessor: row => <BooleanView value={row?.containsActiveDiets} />,
      name: 'isInUse',
      sortable: false,
      filterable: true,
      Filter: ({ onChange, filter }) => (
        <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
      ),
    },
    {
      title: 'addresses.userId',
      accessor: row =>
        row.user ? (
          <NavLink to={`/admin/clients/edit/${row.user.id}`}>
            {row.user.id}
          </NavLink>
        ) : (
          <b>-</b>
        ),
      name: 'user.id',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.city',
      accessor: 'city',
      name: 'city',
      width: 200,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.postcode',
      accessor: 'postCode',
      name: 'postCode',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.address',
      accessor: 'street',
      name: 'street',
      width: 200,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.buildingNo',
      accessor: 'buildNumber',
      name: 'buildNumber',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.zone',
      accessor: row =>
        row.zone ? (
          <NavLink
            to={`/admin/zones/edit/${getNumberFromString(row.zone['@id'])}`}
            target={'_blank'}
          >
            {row.zone.name}
          </NavLink>
        ) : (
          <Danger>
            <b>{t('common.lack', 'brak')}</b>
          </Danger>
        ),
      name: 'zone.name',
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.zoneShort',
      accessor: row =>
        row.zone ? (
          <NavLink
            to={`/admin/zones/edit/${getNumberFromString(row.zone['@id'])}`}
            target={'_blank'}
          >
            {row.zone.shortName}
          </NavLink>
        ) : (
          <b>-</b>
        ),
      name: 'zone.shortName',
      filterable: false,
      sortable: false,
    },
    {
      title: 'addresses.assignedDriver',
      accessor: row =>
        row.driver ? (
          <NavLink
            to={`/admin/drivers/edit/${getNumberFromString(row.driver['@id'])}`}
            target={'_blank'}
          >
            {row.driver.name}
          </NavLink>
        ) : (
          <Danger>
            <b>{t('common.lack', 'brak')}</b>
          </Danger>
        ),
      name: 'driver.name',
      filterable: true,
      sortable: true,
      width: 200,
      Filter: ({ onChange, filter }) => {
        return (
          <div style={{ display: 'flex' }}>
            <input
              onChange={e => {
                onChange(e.target.value);
              }}
              value={filter?.value || ''}
            />
          </div>
        );
      },
    },
  ];
};

export default columnConfig;
