import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import Select from 'components/CustomSelect/Select';
import ModalButton from 'components/CustomButtons/Button';

import Addresses from 'views/Clients/FormTabs/Addresses/List';
import { orderStyles } from 'views/Orders/styles';

import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialButton from '@material-ui/core/Button';

import { goldColor } from 'assets/jss/material-dashboard-pro-react';
import DELIVERY_TYPES from '../../consts/deliveryTypes';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    marginTop: '15px',
    justifyContent: 'space-between',
    '@media (min-width: 1850px)': {
      display: 'flex',
    },
  },
  items: {
    marginTop: '15px',
    '@media (min-width: 1850px)': {
      width: '60%',
      marginTop: '0',
      marginLeft: '15px',
    },
  },

  addNewAddress: {
    marginTop: '15px',
    color: goldColor,
    borderColor: goldColor,
  },
});

const DeliveryAddressSection = ({
  diet = {},
  classes: propClasses,
  pickUpPoints = [],
  deliveryTypes = [],
  selectedCustomer = {},
  customerAddresses = [],
  updateDiet = () => {},
  updateCustomerAddresses = () => {},
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { address, pickUpPoint } = diet ?? {};

  const [deliveryType, setDeliveryType] = useState(null);
  const [isNewAddressDialogOpened, setIsNewAddressDialogOpened] =
    useState(false);

  useEffect(() => {
    setDeliveryType(deliveryTypes[0]);
  }, []);

  useEffect(() => {
    setFirstAvailableOption();
  }, [deliveryType]);

  const setFirstAvailableOption = () => {
    if (deliveryType?.value === DELIVERY_TYPES.ADDRESS) {
      updateDiet({
        ...diet,
        pickUpPoint: null,
        address: isEmpty(customerAddresses)
          ? null
          : customerAddresses.find(address => address.isDefault) ??
            customerAddresses[0],
      });
    }

    if (deliveryType?.value === DELIVERY_TYPES.PICK_UP_POINT) {
      updateDiet({
        ...diet,
        pickUpPoint: isEmpty(pickUpPoints) ? null : pickUpPoints[0],
        address: null,
      });
    }
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <p className={propClasses.labelBlack}>
          {t('orders.deliveryType', '$$Typ dostawy')}
        </p>
        <div className={classes.items} data-cy="delivery_section-delivery-type">
          <Select
            label={t('orders.select', '$$Wybierz')}
            value={deliveryType}
            options={deliveryTypes.filter(el => Object.keys(el).length > 0)}
            handleChange={setDeliveryType}
          />
        </div>
      </div>
      {deliveryType?.value === DELIVERY_TYPES.PICK_UP_POINT && (
        <div className={classes.wrapper}>
          <p className={propClasses.labelBlack}>
            {t('orders.choosePickUpPoint', '$$Wybierz punkt odbioru')}
          </p>
          <div
            className={classes.items}
            data-cy="delivery_section-pickup_point"
          >
            <Select
              label={t('orders.select', '$$Wybierz')}
              value={pickUpPoint}
              options={pickUpPoints}
              handleChange={option => {
                updateDiet({
                  ...diet,
                  pickUpPoint: option,
                  address: null,
                });
              }}
            />
          </div>
        </div>
      )}
      {deliveryType?.value === DELIVERY_TYPES.ADDRESS && (
        <>
          {!isEmpty(customerAddresses) && (
            <div className={classes.wrapper}>
              <p className={propClasses.labelBlack}>
                {t('orders.chooseAddress', '$$Wybierz adres')}
              </p>
              <div className={classes.items}>
                <Select
                  data-cy="delivery_section-choice-address"
                  label={t('orders.select', '$$Wybierz')}
                  value={address}
                  options={customerAddresses}
                  handleChange={option => {
                    updateDiet({
                      ...diet,
                      pickUpPoint: null,
                      address: option,
                    });
                  }}
                />
              </div>
            </div>
          )}
          <MaterialButton
            className={classes.addNewAddress}
            data-cy="delivery_section-add-new-address"
            variant="outlined"
            fullWidth
            onClick={() => setIsNewAddressDialogOpened(true)}
          >
            + {t('orders.addNewAddress', 'Dodaj nowy adres')}
          </MaterialButton>
        </>
      )}
      {isNewAddressDialogOpened ? (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={isNewAddressDialogOpened}
          onClose={() => {
            setIsNewAddressDialogOpened(false);
            updateCustomerAddresses();
          }}
        >
          <DialogContent>
            <ModalButton
              style={orderStyles.closeButton}
              color={'transparent'}
              justIcon
              round
              onClick={() => {
                setIsNewAddressDialogOpened(false);
                updateCustomerAddresses();
              }}
            >
              <Close />
            </ModalButton>
            <Addresses
              noCancel
              userId={selectedCustomer?.id}
              isChangeUrlLoader={false}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
};

export default DeliveryAddressSection;
