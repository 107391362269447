import React from 'react';
import MIME_TYPES from './constants/mimeTypes';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class BoxLabelsMini extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('reports.reportBoxLabelsMini');
    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/bag-stickers-mini"
        mimeType={MIME_TYPES.xlsx}
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_BAG_STICKERS_MINI}
        excelAvaliable={true}
        archiveReportNames={['BAG_STICKERS_MINI']}
        dateType="single"
        isSalesMarketLangViewEnabled={true}
      />
    );
  }
}

export default withTranslation()(BoxLabelsMini);
