import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { combineStyles, isGranted } from 'helpers/helpers';
import { createMuiTheme } from '@material-ui/core/styles/index';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import { useTranslation } from 'react-i18next';

import NavTabs from 'components/NavTabs/NavTabs';
import NotesRecipeForm from './NotesRecipeForm';
import RegularRecipeForm from './RegularRecipeForm';
import roles from 'helpers/roles';
import { Link } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.jsx';

const theme = createMuiTheme({ typography: { useNextVariants: true } });

const RecipeForm = props => {
  const { t } = useTranslation();

  return (
    <div style={{ opacity: props.isLoading ? 0 : 1, transition: '0.5s all' }}>
      <ThemeProvider theme={theme}>
        <NavTabs
          title={
            props.recipeId ? t('form.editRecipe') : t('form.recipeCreation')
          }
          tabs={[
            {
              tabButton: t('common.regular', 'Regular'),
              tabContent: <RegularRecipeForm {...props} />,
            },
            ...(props.isEdit && isGranted(roles.ROLE_NOTE_RECIPE)
              ? [
                  {
                    tabButton: t('common.notes', 'Notes'),
                    tabContent: <NotesRecipeForm {...props} />,
                  },
                ]
              : []),
          ]}
        >
          {props.isEdit && (
            <Link
              to={`/admin/recipes/add/?savedRecipe&selectedBrand=${props.selectedBrand}`}
              target={'_blank'}
            >
              <Button
                color="primary"
                variant="contained"
                size="md"
                onClick={props.saveToLs}
                className={props.classes.button}
              >
                {t('form.copyToNewRecipe', 'Skopiuj do nowego przepisu')}
              </Button>
            </Link>
          )}
        </NavTabs>
      </ThemeProvider>
    </div>
  );
};

const styles = {
  button: { margin: 'unset' },
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle,
  styles
);

const mapStateToProps = state => ({
  ingredients: state.Ingredients.ingredients,
  recipes: state.Recipes.recipes,
  selectedBrand: state.Auth.selectedBrand,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(combinedStyles)(withRouter(RecipeForm)));
